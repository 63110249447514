
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface Subject {
  _id: string;
  name: string;
}

interface Exercise {
  icon: {
    file: string;
    color: string;
  };
  _id: string;
  title: string;
  createdAt: string;
  state: {
    text: string;
    color: string;
  };
  spec: boolean;
  module: string;
  subject: Subject;
  status: string;
  reviewDate: string;
  reviewNote: string;
  type: string;
}

export default defineComponent({
  name: "ExercisesWidget",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const exercises: Exercise[] = [];
    const filteredExercises = ref<Exercise[]>([]);
    const filteredSpecExercises = ref<Exercise[]>([]);

    const subjects = ref<Subject[]>([]);
    const selectedSubject = ref<string>("");
    const selectedSpecSubject = ref<string>("");

    const search = ref<string>("");
    const searchSpec = ref<string>("");

    const loading = ref(true);
    const validateStat = ref<number>(0);
    const validateStatSpec = ref<number>(0);
    setCurrentPageBreadcrumbs(t("course.exercise"), ["Classe"]);

    ApiService.get(`/lms/homework/list/${route.params.id}`)
      .then(({ data }) => {
        data.forEach((exercise) => {
          exercises.push({
            icon: {
              file: "media/icons/duotune/art/art005.svg",
              color: "warning",
            },
            _id: exercise._id,
            title: exercise.title,
            createdAt: exercise.createdAt,
            state: {
              text: exercise.deadline ? exercise.deadline : "-",
              color: "light-warning",
            },
            spec: exercise.students && exercise.students.length != 0,
            subject: exercise.subject,
            module: exercise.module,
            status: exercise.status || "notValidated",
            reviewDate: exercise.reviewDate,
            reviewNote: exercise.reviewNote,
            type: "exercise",
          });

          if (
            !subjects.value.find((s: Subject) => s._id == exercise.subject._id)
          )
            subjects.value.push(exercise.subject);
        });
        ApiService.get(`/lms/quiz/list/${route.params.id}`)
          .then(({ data }) => {
            data.forEach((exercise) => {
              exercises.push({
                icon: {
                  file: "media/icons/duotune/art/art002.svg",
                  color: "info",
                },
                _id: exercise._id,
                title: exercise.title,
                createdAt: exercise.createdAt,
                state: {
                  text: exercise.deadline ? exercise.deadline : "-",
                  color: "light-warning",
                },
                spec: exercise.students && exercise.students.length != 0,
                subject: exercise.subject,
                module: exercise.module,
                status: exercise.status || "notValidated",
                reviewDate: exercise.reviewDate,
                reviewNote: exercise.reviewNote,
                type: "quiz",
              });

              if (
                !subjects.value.find(
                  (s: Subject) => s._id == exercise.subject._id
                )
              )
                subjects.value.push(exercise.subject);
            });

            const sortedExercises = exercises.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
            filteredExercises.value = sortedExercises.filter((ex) => !ex.spec);
            filteredSpecExercises.value = sortedExercises.filter(
              (ex) => ex.spec
            );
          })
          .catch((e) => console.log(e))
          .finally(() => {
            loading.value = false;
          });
      })
      .catch((e) => console.log(e));

    const filterExercises = () => {
      currentPage.value = 1;
      filteredExercises.value = exercises.filter(
        (exercise) =>
          exercise.title.toLowerCase().includes(search.value.toLowerCase()) &&
          !exercise.spec &&
          (exercise.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          ((exercise.status === "validated" && validateStat.value == 1) ||
            (exercise.status === "notValidated" && validateStat.value == 2) ||
            (exercise.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
      );
    };

    const filterSpecExercises = () => {
      currentSpecPage.value = 1;
      filteredSpecExercises.value = exercises.filter(
        (exercise) =>
          exercise.title
            .toLowerCase()
            .includes(searchSpec.value.toLowerCase()) &&
          exercise.spec &&
          (exercise.subject._id == selectedSpecSubject.value ||
            selectedSpecSubject.value == "") &&
          ((exercise.status === "validated" && validateStatSpec.value == 1) ||
            (exercise.status === "notValidated" &&
              validateStatSpec.value == 2) ||
            (exercise.status === "rejected" && validateStatSpec.value == 3) ||
            validateStatSpec.value == 0)
      );
    };

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredExercises.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredExercises.value.slice(startIndex - 1, endIndex);
    });

    const currentSpecPage = ref<number>(1);

    const totalSpecPages = computed(() =>
      Math.ceil(filteredSpecExercises.value.length / perPage)
    );

    const pageSpecRecords = computed(() => {
      const startIndex = perPage * (currentSpecPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredSpecExercises.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      exercises,
      search,
      searchSpec,
      moment,
      filterExercises,
      filterSpecExercises,
      filteredExercises,
      filteredSpecExercises,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      currentSpecPage,
      totalSpecPages,
      pageSpecRecords,
      subjects,
      selectedSubject,
      selectedSpecSubject,
      loading,
      validateStat,
      validateStatSpec,
    };
  },
});
